import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      title: _ctx.Title,
      visible: _ctx.visible,
      "confirm-loading": _ctx.confirmLoading,
      onOk: _ctx.handleOk,
      onCancel: _ctx.cancel
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.formState,
          rules: _ctx.rules,
          "label-col": _ctx.labelCol,
          "wrapper-col": _ctx.wrapperCol
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "类别名称：",
              name: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.name,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "类别描述：",
              name: "comment"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_textarea, {
                  value: _ctx.formState.comment,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.comment) = $event)),
                  showCount: "",
                  maxlength: 20
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "label-col", "wrapper-col"])
      ]),
      _: 1
    }, 8, ["title", "visible", "confirm-loading", "onOk", "onCancel"])
  ]))
}