
import { ref, defineComponent, UnwrapRef, reactive, toRaw } from "vue";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import { addCommodity, updateCommodity } from "@/api/api"
interface FormState {
  name: string;
  comment: string;
  categoryId: string;
}
export default defineComponent({
  name: "index",
  props: {
    Title: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    var formState: UnwrapRef<FormState> = reactive({
      name: '',
      comment: '',
      categoryId: ''
    });
    const visible = ref<boolean>(false);
    const confirmLoading = ref<boolean>(false);
    var valueData = ref({})
    const showModal = (val: any) => {
      visible.value = true;
      if (val) {
        valueData.value = val
        formState.name = val.name
        formState.comment = val.comment
        formState.categoryId = val.categoryId
      } else {
        valueData.value = {}
        formState.name = ''
        formState.comment = ''
        formState.categoryId = ''
      }
    };
    const formRef = ref();
    const handleOk = () => {
      formRef.value
        .validate()
        .then(() => {
          var params = { ...formState, type: 1 };
          if (props.Title == "新增题库类别") {
            params.type = 1
          }
          if (props.Title == "新增资料类别") {
            params.type = 2
          }
          if (props.Title == "新增培训类别") {
            params.type = 3
          }
          if (props.Title == "修改题库类别") {
            params.type = 1
          }
          if (props.Title == "修改资料类别") {
            params.type = 2
          }
          if (props.Title == "修改培训类别") {
            params.type = 3
          }
          if (JSON.stringify(valueData.value) !== '{}') {
            updateCommodity(params).then(res => {
              visible.value = false;
              confirmLoading.value = false;
              emit('changeTable', '')
              cancel();
            })
          } else {
            addCommodity(params).then(res => {
              visible.value = false;
              confirmLoading.value = false;
              emit('changeTable', '')
              cancel();
            })
          }
          confirmLoading.value = true;
        })
        .catch((error: ValidateErrorEntity<FormState>) => {
          console.log("error", error);
        });
    };
    const cancel = () => {
      formRef.value.resetFields();
      visible.value = false;
      confirmLoading.value = false;
    };
    const rules = {
      name: [
        {
          required: true,
          message: "不能为空",
          trigger: "blur",
        },
      ],
      comment: [
        {
          required: true,
          message: "不能为空",
          trigger: "blur",
        },
      ],
    };
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formState,
      visible,
      confirmLoading,
      cancel,
      showModal,
      handleOk,
      formRef,
      rules,
    };
  },
});
